import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import SEO from "../components/SEO";

const Main = styled.div`
  min-height: 30vh;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
`;

const StyledH1 = styled.h1`
  padding: 1rem;
  font-size: 2rem;
`;

const NewBooksSection = styled.div``;

const FavoriteSection = styled.div``;

const SectionHeader = styled.h1`
  padding: 1rem;
  font-size: 2rem;
  color: white;
  border-bottom: 1px solid #999999;
  text-align: center;
`;

const Book = styled.div`
  width: 550px;
  padding: 0.1rem;
  margin-top: 1rem;
  color: white;
  border: 1px solid #999999;
  border-radius: 10px;

  a {
    color: white;
  }
`;

const BookImg = styled.div`
  padding: 0.1rem;
`;

const BookInfo = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
`;

const BookTitle = styled.h1`
  padding: 1rem;
  background: #20232a;
  border-radius: 0.5rem 0.5rem 0 0;
  border-bottom: 1px solid #999999;
  font-size: 1.4rem;
  font-weight: 700;
`;

const BookDetailList = styled.div`
  width: 300px;
  padding: 0.1rem;
`;

const BookDetail = styled.p`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: solid 1px #999999;
`;

const BooksPage = () => {
  return (
    <Layout title="Books">
      <SEO pagetitle="Books" pagedesc="将棋の本について" />
      <ContentWrapper>
        <Main>
          <NewBooksSection>
            <SectionHeader>あたらしい本</SectionHeader>
            <Book>
              <a href="https://amzn.to/3FnduxC">
                <BookTitle>藤井聡太の鬼手 令和元年・2年度版</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E8%97%A4%E4%BA%95%E8%81%A1%E5%A4%AA%E3%81%AE%E9%AC%BC%E6%89%8B-%E4%BB%A4%E5%92%8C%E5%85%83%E5%B9%B4%E3%83%BB2%E5%B9%B4%E5%BA%A6%E7%89%88-%E6%9B%B8%E7%B1%8D%E7%B7%A8%E9%9B%86%E9%83%A8/dp/4839979030?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642075464&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-4&linkCode=li3&tag=iruda02-22&linkId=f966a956b269cc0d6099ad730a3a7702&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839979030&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4839979030"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年2月25日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span></span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3GpMZJf">
                <BookTitle>
                  実戦の終盤力を鍛える3・5・7手 ランダム詰将棋
                </BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E5%AE%9F%E6%88%A6%E3%81%AE%E7%B5%82%E7%9B%A4%E5%8A%9B%E3%82%92%E9%8D%9B%E3%81%88%E3%82%8B3%E3%83%BB5%E3%83%BB7%E6%89%8B-%E3%83%A9%E3%83%B3%E3%83%80%E3%83%A0%E8%A9%B0%E5%B0%86%E6%A3%8B-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8B%E6%96%87%E5%BA%AB-%E7%B6%BE%E7%80%AC-%E7%A0%94/dp/4839979073?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642075464&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-5&linkCode=li3&tag=iruda02-22&linkId=8e7fe513573d9172668d00f7bef750da&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839979073&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4839979073"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年2月25日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>綾瀬 研</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>文庫</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3qob79j">
                <BookTitle>ＮＨＫ将棋講座　2022年3月号</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%EF%BC%AE%EF%BC%A8%EF%BC%AB%E5%B0%86%E6%A3%8B%E8%AC%9B%E5%BA%A7-2022%E5%B9%B43%E6%9C%88%E5%8F%B7-%EF%BC%BB%E9%9B%91%E8%AA%8C%EF%BC%BD-%EF%BC%AE%EF%BC%A8%EF%BC%AB-%E5%B0%86%E6%A3%8B%E8%AC%9B%E5%BA%A7-NHK%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88-ebook/dp/B08XMRP7XZ?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642075464&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-6&linkCode=li3&tag=iruda02-22&linkId=a4230b41c24bf362359e0853654e6864&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08XMRP7XZ&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B08XMRP7XZ"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>NHK出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年2月16日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>NHK</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3qk7kdb">
                <BookTitle>横歩取り新時代 青野流完全撃退マニュアル</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E6%A8%AA%E6%AD%A9%E5%8F%96%E3%82%8A%E6%96%B0%E6%99%82%E4%BB%A3-%E9%9D%92%E9%87%8E%E6%B5%81%E5%AE%8C%E5%85%A8%E6%92%83%E9%80%80%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8BBOOKS-%E9%A3%AF%E5%B3%B6-%E6%A0%84%E6%B2%BB/dp/4839979200?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642075464&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-7&linkCode=li3&tag=iruda02-22&linkId=fe92bf1b5e7c05a866d61914878e18e3&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839979200&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4839979200"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年2月15日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>飯島 栄治</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3fBwUEB">
                <BookTitle>羽生善治監修 終盤戦を制する次の一手</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E7%BE%BD%E7%94%9F%E5%96%84%E6%B2%BB%E7%9B%A3%E4%BF%AE-%E7%B5%82%E7%9B%A4%E6%88%A6%E3%82%92%E5%88%B6%E3%81%99%E3%82%8B%E6%AC%A1%E3%81%AE%E4%B8%80%E6%89%8B-%E5%B0%86%E6%A3%8B%E4%B8%96%E7%95%8C-ebook/dp/B09PTCHWYP?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642075464&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-8&linkCode=li3&tag=iruda02-22&linkId=ad96a38739124c785f1905a3f5108735&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09PTCHWYP&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B09PTCHWYP"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年2月15日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>羽生 善治（監修）</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3HYWeAo">
                <BookTitle>将棋記者が迫る 棋士の勝負哲学</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E5%B0%86%E6%A3%8B%E8%A8%98%E8%80%85%E3%81%8C%E8%BF%AB%E3%82%8B-%E6%A3%8B%E5%A3%AB%E3%81%AE%E5%8B%9D%E8%B2%A0%E5%93%B2%E5%AD%A6-%E6%9C%9D%E6%97%A5%E6%96%B0%E8%81%9E%E8%A8%98%E8%80%85-%E6%9D%91%E7%80%AC%E4%BF%A1%E4%B9%9F/dp/4344790642?_encoding=UTF8&qid=1642084085&sr=1-11&linkCode=li3&tag=iruda02-22&linkId=827b8a416c36b03e2e68958303107368&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4344790642&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4344790642"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>幻冬舎</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年1月26日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>村瀬 信也</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/33uX6xQ">
                <BookTitle>久保利明　さばきの一手</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E4%B9%85%E4%BF%9D%E5%88%A9%E6%98%8E-%E3%81%95%E3%81%B0%E3%81%8D%E3%81%AE%E4%B8%80%E6%89%8B-%E4%B9%85%E4%BF%9D%E5%88%A9%E6%98%8E-ebook/dp/B09N8VWL2R?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642075464&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-13&linkCode=li3&tag=iruda02-22&linkId=a46a8fd8102c02d82dc55cbf6fd48777&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09N8VWL2R&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B09N8VWL2R"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年1月24日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>久保 利明</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3fkLMH7">
                <BookTitle>矢倉戦記 2011~2021</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E7%9F%A2%E5%80%89%E6%88%A6%E8%A8%98-2011-2021-%E9%AB%98%E6%A9%8B-%E9%81%93%E9%9B%84/dp/4839978891?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642075464&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-12&linkCode=li3&tag=iruda02-22&linkId=9dc0e753dd4c6ac21a63c7d316748d11&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839978891&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4839978891"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年1月24日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>高橋 道雄</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3fnpPHk">
                <BookTitle>決め手将棋! 次の三手185問</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E6%B1%BA%E3%82%81%E6%89%8B%E5%B0%86%E6%A3%8B-%E6%AC%A1%E3%81%AE%E4%B8%89%E6%89%8B185%E5%95%8F-%E6%A3%AE-%E4%BF%A1%E9%9B%84/dp/4408640476?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642075464&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-16&linkCode=li3&tag=iruda02-22&linkId=8ce571c941aa6b8b4e207cf6a8bec5c9&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4408640476&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4408640476"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>実業之日本社</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年1月20日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>森 信雄</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>文庫</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3qnVjDJ">
                <BookTitle>
                  改訂版 羽生善治のみるみる強くなる将棋入門 序盤の指し方
                </BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E6%94%B9%E8%A8%82%E7%89%88-%E7%BE%BD%E7%94%9F%E5%96%84%E6%B2%BB%E3%81%AE%E3%81%BF%E3%82%8B%E3%81%BF%E3%82%8B%E5%BC%B7%E3%81%8F%E3%81%AA%E3%82%8B%E5%B0%86%E6%A3%8B%E5%85%A5%E9%96%80-%E5%BA%8F%E7%9B%A4%E3%81%AE%E6%8C%87%E3%81%97%E6%96%B9-%E7%BE%BD%E7%94%9F-%E5%96%84%E6%B2%BB/dp/4262101622?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642076452&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-17&linkCode=li3&tag=iruda02-22&linkId=1f6d089f2624242c3f191fe8a38a7d4e&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4262101622&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4262101622"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>池田書店</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年1月19日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>羽生 善治（監修）</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3GtqbIC">
                <BookTitle>
                  改訂版 羽生善治のみるみる強くなる将棋入門 終盤の勝ち方
                </BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E6%94%B9%E8%A8%82%E7%89%88-%E7%BE%BD%E7%94%9F%E5%96%84%E6%B2%BB%E3%81%AE%E3%81%BF%E3%82%8B%E3%81%BF%E3%82%8B%E5%BC%B7%E3%81%8F%E3%81%AA%E3%82%8B%E5%B0%86%E6%A3%8B%E5%85%A5%E9%96%80-%E7%B5%82%E7%9B%A4%E3%81%AE%E5%8B%9D%E3%81%A1%E6%96%B9-%E7%BE%BD%E7%94%9F-%E5%96%84%E6%B2%BB/dp/4262101630?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642076452&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-18&linkCode=li3&tag=iruda02-22&linkId=481ee24ec860b96229117e98bba12a86&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4262101630&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4262101630"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>池田書店</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年1月19日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>羽生 善治（監修）</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3I2i4D3">
                <BookTitle>将棋・ひと目の受け</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E5%B0%86%E6%A3%8B%E3%83%BB%E3%81%B2%E3%81%A8%E7%9B%AE%E3%81%AE%E5%8F%97%E3%81%91-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8B%E6%96%87%E5%BA%AB-%E6%9B%B8%E7%B1%8D%E7%B7%A8%E9%9B%86%E9%83%A8/dp/4839977828?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642076452&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-19&linkCode=li3&tag=iruda02-22&linkId=9d4ec4baf332c991da0605964fe5ef9f&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839977828&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4839977828"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年1月17日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>永瀬拓矢（監修）</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、文庫</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3qlLS7J">
                <BookTitle>１手ずつ解説する角交換四間飛車</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%EF%BC%91%E6%89%8B%E3%81%9A%E3%81%A4%E8%A7%A3%E8%AA%AC%E3%81%99%E3%82%8B%E8%A7%92%E4%BA%A4%E6%8F%9B%E5%9B%9B%E9%96%93%E9%A3%9B%E8%BB%8A-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8BBOOKS-%E9%96%80%E5%80%89%E5%95%93%E5%A4%AA-ebook/dp/B09MT82D35?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642076452&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-20&linkCode=li3&tag=iruda02-22&linkId=19fb005aa45024747e1f93f9525761b2&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09MT82D35&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B09MT82D35"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年1月17日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>門倉 啓太</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3FtE1cu">
                <BookTitle>ＮＨＫ将棋講座　2022年2月号</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%EF%BC%AE%EF%BC%A8%EF%BC%AB%E5%B0%86%E6%A3%8B%E8%AC%9B%E5%BA%A7-2022%E5%B9%B42%E6%9C%88%E5%8F%B7-%EF%BC%BB%E9%9B%91%E8%AA%8C%EF%BC%BD-%EF%BC%AE%EF%BC%A8%EF%BC%AB-%E5%B0%86%E6%A3%8B%E8%AC%9B%E5%BA%A7-NHK%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88-ebook/dp/B08XMRVMHH?_encoding=UTF8&qid=1642083954&sr=1-21&linkCode=li3&tag=iruda02-22&linkId=4e7d6866c755cada9f22e8da6386f3ac&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08XMRVMHH&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B08XMRVMHH"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>NHK出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年1月15日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>NHK</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3noCdLB">
                <BookTitle>榧野香一の榧香る３手詰にゃ</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E6%A6%A7%E9%87%8E%E9%A6%99%E4%B8%80%E3%81%AE%E6%A6%A7%E9%A6%99%E3%82%8B%EF%BC%93%E6%89%8B%E8%A9%B0%E3%81%AB%E3%82%83-%E5%9D%82%E7%94%B0%E6%85%8E%E5%90%BE/dp/B09PHF9BM8?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642076452&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-28&linkCode=li3&tag=iruda02-22&linkId=40985f3e4dc71f9b1c5b336b37d84ff4&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09PHF9BM8&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B09PHF9BM8"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span></span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年1月1日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>坂田 慎吾</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、ペーパーバック</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3qlMc6r">
                <BookTitle>将棋世界 2022年2月号</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E5%B0%86%E6%A3%8B%E4%B8%96%E7%95%8C-2022%E5%B9%B42%E6%9C%88%E5%8F%B7%EF%BC%88%E4%BB%98%E9%8C%B2%E3%82%BB%E3%83%83%E3%83%88%EF%BC%89-%E9%9B%91%E8%AA%8C-%E3%80%8C%E5%B0%86%E6%A3%8B%E4%B8%96%E7%95%8C%E3%80%8D%E7%B7%A8%E9%9B%86%E9%83%A8-ebook/dp/B09P4KYXR7?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642076452&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-29&linkCode=li3&tag=iruda02-22&linkId=97903459b3e74c79285ddce5d102c497&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09P4KYXR7&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B09P4KYXR7"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2021年12月28日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>将棋世界</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、雑誌</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/31XBgma">
                <BookTitle>
                  端攻めマスターが伝授
                  端攻めのテクニック　井出隼平五段（将棋世界2022年2月号付録）
                </BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E7%AB%AF%E6%94%BB%E3%82%81%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E3%81%8C%E4%BC%9D%E6%8E%88-%E7%AB%AF%E6%94%BB%E3%82%81%E3%81%AE%E3%83%86%E3%82%AF%E3%83%8B%E3%83%83%E3%82%AF-%E4%BA%95%E5%87%BA%E9%9A%BC%E5%B9%B3%E4%BA%94%E6%AE%B5%EF%BC%88%E5%B0%86%E6%A3%8B%E4%B8%96%E7%95%8C2022%E5%B9%B42%E6%9C%88%E5%8F%B7%E4%BB%98%E9%8C%B2%EF%BC%89-%E3%80%8C%E5%B0%86%E6%A3%8B%E4%B8%96%E7%95%8C%E3%80%8D%E7%B7%A8%E9%9B%86%E9%83%A8-ebook/dp/B09P4M7SPT?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642076452&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-30&linkCode=li3&tag=iruda02-22&linkId=b09bf8fd2da1932bac7f11b9c525e780&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09P4M7SPT&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B09P4M7SPT"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2021年12月28日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>将棋世界</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3rcieRG">
                <BookTitle>竜王 藤井聡太 (将棋世界Special)</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E7%AB%9C%E7%8E%8B-%E8%97%A4%E4%BA%95%E8%81%A1%E5%A4%AA-%E5%B0%86%E6%A3%8B%E4%B8%96%E7%95%8CSpecial-%E5%B0%86%E6%A3%8B%E4%B8%96%E7%95%8C-ebook/dp/B09PB6QF5J?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642076452&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-31&linkCode=li3&tag=iruda02-22&linkId=9640df22f8112394e1a21450b101899f&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09PB6QF5J&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B09PB6QF5J"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2021年12月27日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>将棋世界</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、ムック本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3qoMUzZ">
                <BookTitle>黄楊一輝の詰将棋　初陣</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E9%BB%84%E6%A5%8A%E4%B8%80%E8%BC%9D%E3%81%AE%E8%A9%B0%E5%B0%86%E6%A3%8B-%E5%88%9D%E9%99%A3-%E5%9D%82%E7%94%B0%E6%85%8E%E5%90%BE/dp/B09NWS19P2?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642076452&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-32&linkCode=li3&tag=iruda02-22&linkId=272445615d1ed3e14255ea5665feea6c&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09NWS19P2&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B09NWS19P2"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span></span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2021年12月26日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>坂田 慎吾</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、ペーパーバック</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3GpQ0Jz">
                <BookTitle>黄楊一輝の１手詰屋さん</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E9%BB%84%E6%A5%8A%E4%B8%80%E8%BC%9D%E3%81%AE%EF%BC%91%E6%89%8B%E8%A9%B0%E5%B1%8B%E3%81%95%E3%82%93-%E5%9D%82%E7%94%B0%E6%85%8E%E5%90%BE/dp/B09P7QDSFW?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642077060&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-33&linkCode=li3&tag=iruda02-22&linkId=832cafafa2363034d07e58863000327a&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09P7QDSFW&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B09P7QDSFW"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span></span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2021年12月26日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>坂田 慎吾</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、ペーパーバック</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3Foh4HI">
                <BookTitle>羽生善治の実戦次の一手</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E7%BE%BD%E7%94%9F%E5%96%84%E6%B2%BB%E3%81%AE%E5%AE%9F%E6%88%A6%E6%AC%A1%E3%81%AE%E4%B8%80%E6%89%8B-%E7%BE%BD%E7%94%9F%E5%96%84%E6%B2%BB/dp/4309289452?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642077060&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-35&linkCode=li3&tag=iruda02-22&linkId=433bb5cfc135f685f84b03947f2e8de5&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4309289452&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4309289452"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>河出書房新社</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2021年12月25日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>羽生善治</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3A1TY8H">
                <BookTitle>黄楊一輝の詰将棋　三時</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E9%BB%84%E6%A5%8A%E4%B8%80%E8%BC%9D%E3%81%AE%E8%A9%B0%E5%B0%86%E6%A3%8B-%E4%B8%89%E6%99%82-%E5%9D%82%E7%94%B0%E6%85%8E%E5%90%BE/dp/B09NV55481?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2CES0S0RHJ6CX&keywords=%E5%B0%86%E6%A3%8B&qid=1642077060&s=books&sprefix=%E5%B0%86%E6%A3%8B%2Cstripbooks%2C184&sr=1-36&linkCode=li3&tag=iruda02-22&linkId=9aaf1f8986836aee8883531f4832cd0e&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09NV55481&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B09NV55481"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span></span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2021年12月25日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>坂田 慎吾</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、ペーパーバック</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
          </NewBooksSection>
          <FavoriteSection>
            <SectionHeader>おすすめの本</SectionHeader>
            <Book>
              <a href="https://amzn.to/3K8iWrO">
                <BookTitle>藤井聡太推薦! 将棋が強くなる実戦1手詰</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E8%97%A4%E4%BA%95%E8%81%A1%E5%A4%AA%E6%8E%A8%E8%96%A6-%E5%B0%86%E6%A3%8B%E3%81%8C%E5%BC%B7%E3%81%8F%E3%81%AA%E3%82%8B%E5%AE%9F%E6%88%A61%E6%89%8B%E8%A9%B0-%E6%9B%B8%E7%B1%8D%E7%B7%A8%E9%9B%86%E9%83%A8/dp/4839965196?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2XUF3KHXYF48O&keywords=%E8%97%A4%E4%BA%95%E8%81%A1%E5%A4%AA%E6%8E%A8%E8%96%A6&qid=1642077540&s=books&sprefix=%E8%97%A4%E4%BA%95%E8%81%A1%E5%A4%AA%E6%8E%A8%E8%96%A6%2Cstripbooks%2C144&sr=1-3&linkCode=li3&tag=iruda02-22&linkId=5304750bf38ef260cfe41b6414589c5e&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839965196&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4839965196"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2018年1月23日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>マイナビ 書籍編集部</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3I7Cvi9">
                <BookTitle>藤井聡太推薦! 将棋が強くなる基本3手詰</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E8%97%A4%E4%BA%95%E8%81%A1%E5%A4%AA%E6%8E%A8%E8%96%A6-%E5%B0%86%E6%A3%8B%E3%81%8C%E5%BC%B7%E3%81%8F%E3%81%AA%E3%82%8B%E5%9F%BA%E6%9C%AC3%E6%89%8B%E8%A9%B0-%E6%9B%B8%E7%B1%8D%E7%B7%A8%E9%9B%86%E9%83%A8/dp/4839965765?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2XUF3KHXYF48O&keywords=%E8%97%A4%E4%BA%95%E8%81%A1%E5%A4%AA%E6%8E%A8%E8%96%A6&qid=1642077540&s=books&sprefix=%E8%97%A4%E4%BA%95%E8%81%A1%E5%A4%AA%E6%8E%A8%E8%96%A6%2Cstripbooks%2C144&sr=1-1&linkCode=li3&tag=iruda02-22&linkId=4b7de32f54dc7b7872afab8b69da5592&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839965765&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4839965765"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2018年10月23日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>マイナビ 書籍編集部</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/31XyRYE">
                <BookTitle>藤井聡太推薦! 将棋が強くなる明解5手詰</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E8%97%A4%E4%BA%95%E8%81%A1%E5%A4%AA%E6%8E%A8%E8%96%A6-%E5%B0%86%E6%A3%8B%E3%81%8C%E5%BC%B7%E3%81%8F%E3%81%AA%E3%82%8B%E6%98%8E%E8%A7%A35%E6%89%8B%E8%A9%B0-%E6%9B%B8%E7%B1%8D%E7%B7%A8%E9%9B%86%E9%83%A8/dp/4839971722?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2XUF3KHXYF48O&keywords=%E8%97%A4%E4%BA%95%E8%81%A1%E5%A4%AA%E6%8E%A8%E8%96%A6&qid=1642077540&s=books&sprefix=%E8%97%A4%E4%BA%95%E8%81%A1%E5%A4%AA%E6%8E%A8%E8%96%A6%2Cstripbooks%2C144&sr=1-2&linkCode=li3&tag=iruda02-22&linkId=6aae1c4cd5c3274c564f80851d4a41bb&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839971722&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4839971722"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2020年1月23日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>マイナビ 書籍編集部</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3KaroGG">
                <BookTitle>1手詰ハンドブック</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/1%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF-%E6%B5%A6%E9%87%8E-%E7%9C%9F%E5%BD%A6/dp/4839933324?keywords=%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF&qid=1642077734&s=books&sprefix=%E6%89%8B%E8%A9%B0%2Cstripbooks%2C157&sr=1-1&linkCode=li3&tag=iruda02-22&linkId=62a4b1921bc80bded9f8782fd5cee615&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839933324&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4839933324"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>毎日コミュニケーションズ</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2009年11月25日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>浦野 真彦</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3zUuA4E">
                <BookTitle>3手詰ハンドブック 新版</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/3%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF-%E6%B5%A6%E9%87%8E-%E7%9C%9F%E5%BD%A6/dp/4861370329?keywords=%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF&qid=1642077734&s=books&sprefix=%E6%89%8B%E8%A9%B0%2Cstripbooks%2C157&sr=1-2&linkCode=li3&tag=iruda02-22&linkId=6b06ac9c168795f197084151da49415f&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4861370329&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4861370329"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>浅川書房</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2011年9月1日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>浦野 真彦</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3GttTlw">
                <BookTitle>3手詰ハンドブック〈2〉</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/3%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF%E3%80%882%E3%80%89-%E6%B5%A6%E9%87%8E-%E7%9C%9F%E5%BD%A6/dp/486137040X?keywords=%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF&qid=1642077734&s=books&sprefix=%E6%89%8B%E8%A9%B0%2Cstripbooks%2C157&sr=1-3&linkCode=li3&tag=iruda02-22&linkId=387b0e7f83f89c443b520f5a10521e20&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=486137040X&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=486137040X"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>浅川書房</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2013年5月1日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>浦野 真彦</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3zTBJSX">
                <BookTitle>5手詰ハンドブック 新版</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/5%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF-%E6%B5%A6%E9%87%8E-%E7%9C%9F%E5%BD%A6/dp/4861370353?keywords=%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF&qid=1642077734&s=books&sprefix=%E6%89%8B%E8%A9%B0%2Cstripbooks%2C157&sr=1-5&linkCode=li3&tag=iruda02-22&linkId=85fcad1f9b568fb49ce402835bc28cf1&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4861370353&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4861370353"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>浅川書房</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2012年3月1日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>浦野 真彦</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3rytlof">
                <BookTitle>5手詰ハンドブック〈2〉</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/5%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF%E3%80%882%E3%80%89-%E6%B5%A6%E9%87%8E-%E7%9C%9F%E5%BD%A6/dp/4861370418?keywords=%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF&qid=1642077734&s=books&sprefix=%E6%89%8B%E8%A9%B0%2Cstripbooks%2C157&sr=1-7&linkCode=li3&tag=iruda02-22&linkId=7b57152c3bbc2c23c531dceb9ae44328&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4861370418&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4861370418"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>浅川書房</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2013年12月1日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>浦野 真彦</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3zUDuiu">
                <BookTitle>5手詰ハンドブック〈3〉</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/5%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF%E3%80%883%E3%80%89-%E6%B5%A6%E9%87%8E-%E7%9C%9F%E5%BD%A6/dp/4861370507?keywords=%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF&qid=1642077734&s=books&sprefix=%E6%89%8B%E8%A9%B0%2Cstripbooks%2C157&sr=1-8&linkCode=li3&tag=iruda02-22&linkId=1494e83790d0818c10f45def7efff27b&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4861370507&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4861370507"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>浅川書房</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2019年10月28日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>浦野 真彦</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3FpGc0w">
                <BookTitle>7手詰ハンドブック</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/7%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF-%E6%B5%A6%E9%87%8E-%E7%9C%9F%E5%BD%A6/dp/4861370310?keywords=%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF&qid=1642077734&s=books&sprefix=%E6%89%8B%E8%A9%B0%2Cstripbooks%2C157&sr=1-4&linkCode=li3&tag=iruda02-22&linkId=c47fee4257ecdb8bbd3d2bc0af1eadc7&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4861370310&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4861370310"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>浅川書房</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2011年5月1日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>浦野 真彦</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3HSH7Zg">
                <BookTitle>7手詰ハンドブック〈2〉</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/7%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF%E3%80%882%E3%80%89-%E6%B5%A6%E9%87%8E-%E7%9C%9F%E5%BD%A6/dp/4861370442?keywords=%E6%89%8B%E8%A9%B0%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF&qid=1642077734&s=books&sprefix=%E6%89%8B%E8%A9%B0%2Cstripbooks%2C157&sr=1-6&linkCode=li3&tag=iruda02-22&linkId=b3a046be9f8e7d9cb740ad64c329a8d2&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4861370442&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4861370442"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>浅川書房</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2015年5月16日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>浦野 真彦</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/31XAxkU">
                <BookTitle>寄せの手筋200</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E5%AF%84%E3%81%9B%E3%81%AE%E6%89%8B%E7%AD%8B200-%E6%9C%80%E5%BC%B7%E5%B0%86%E6%A3%8B%E3%83%AC%E3%82%AF%E3%83%81%E3%83%A3%E3%83%BC%E3%83%96%E3%83%83%E3%82%AF%E3%82%B9-%E9%87%91%E5%AD%90%E3%82%BF%E3%82%AB%E3%82%B7/dp/4861370272?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2YDLYRJN1AG0J&keywords=%E9%87%91%E5%AD%90%E3%82%BF%E3%82%AB%E3%82%B7&qid=1642078329&s=books&sprefix=%E9%87%91%E5%AD%90%E3%82%BF%E3%82%AB%E3%82%B7%2Cstripbooks%2C161&sr=1-1&linkCode=li3&tag=iruda02-22&linkId=36b175b976b6995e0d766c4238e1c47e&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4861370272&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4861370272"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>浅川書房</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2010年4月10日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>金子 タカシ</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3fqcEFu">
                <BookTitle>美濃崩し200</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E7%BE%8E%E6%BF%83%E5%B4%A9%E3%81%97200-%E6%9C%80%E5%BC%B7%E5%B0%86%E6%A3%8B%E3%83%AC%E3%82%AF%E3%83%81%E3%83%A3%E3%83%BC%E3%83%96%E3%83%83%E3%82%AF%E3%82%B9-%E9%87%91%E5%AD%90-%E3%82%BF%E3%82%AB%E3%82%B7/dp/4861370299?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2YDLYRJN1AG0J&keywords=%E9%87%91%E5%AD%90%E3%82%BF%E3%82%AB%E3%82%B7&qid=1642078329&s=books&sprefix=%E9%87%91%E5%AD%90%E3%82%BF%E3%82%AB%E3%82%B7%2Cstripbooks%2C161&sr=1-3&linkCode=li3&tag=iruda02-22&linkId=aa72f8b68d45bf68dcc143a463f8ab50&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4861370299&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4861370299"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>浅川書房</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2010年12月1日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>金子 タカシ</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3nmKoIw">
                <BookTitle>凌ぎの手筋200</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E5%87%8C%E3%81%8E%E3%81%AE%E6%89%8B%E7%AD%8B200-%E6%9C%80%E5%BC%B7%E5%B0%86%E6%A3%8B%E3%83%AC%E3%82%AF%E3%83%81%E3%83%A3%E3%83%BC%E3%83%96%E3%83%83%E3%82%AF%E3%82%B9-%E9%87%91%E5%AD%90-%E3%82%BF%E3%82%AB%E3%82%B7/dp/4861370396?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2YDLYRJN1AG0J&keywords=%E9%87%91%E5%AD%90%E3%82%BF%E3%82%AB%E3%82%B7&qid=1642078329&s=books&sprefix=%E9%87%91%E5%AD%90%E3%82%BF%E3%82%AB%E3%82%B7%2Cstripbooks%2C161&sr=1-2&linkCode=li3&tag=iruda02-22&linkId=7997652a264f3a9d4cf4ab5dd1d996e9&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4861370396&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4861370396"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>浅川書房</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2013年4月3日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>金子 タカシ</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3rdOYdg">
                <BookTitle>
                  将棋・ひと目の手筋―初級の壁を突破する208問
                </BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%E5%B0%86%E6%A3%8B%E3%83%BB%E3%81%B2%E3%81%A8%E7%9B%AE%E3%81%AE%E6%89%8B%E7%AD%8B%E2%80%95%E5%88%9D%E7%B4%9A%E3%81%AE%E5%A3%81%E3%82%92%E7%AA%81%E7%A0%B4%E3%81%99%E3%82%8B208%E5%95%8F-MYCOM%E5%B0%86%E6%A3%8B%E6%96%87%E5%BA%ABSP-%E6%B8%A1%E8%BE%BA-%E6%98%8E/dp/4839921334?keywords=%E3%81%B2%E3%81%A8%E7%9B%AE%E3%81%AE%E6%89%8B%E7%AD%8B&qid=1642078554&s=books&sprefix=%E3%81%B2%E3%81%A8%E7%9B%AE%2Cstripbooks%2C158&sr=1-1&linkCode=li3&tag=iruda02-22&linkId=7a92e0d8a596e0a2cff7607dd1a01e06&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839921334&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4839921334"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>毎日コミュニケーションズ</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2006年8月1日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>渡辺 明（監修）</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3qlWe7E">
                <BookTitle>1手ずつ解説する四間飛車</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%EF%BC%91%E6%89%8B%E3%81%9A%E3%81%A4%E8%A7%A3%E8%AA%AC%E3%81%99%E3%82%8B%E5%9B%9B%E9%96%93%E9%A3%9B%E8%BB%8A-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8BBOOKS-%E8%A5%BF%E7%94%B0%E6%8B%93%E4%B9%9F-ebook/dp/B08N4D6XWP?keywords=1%E6%89%8B%E3%81%9A%E3%81%A4%E8%A7%A3%E8%AA%AC%E3%81%99%E3%82%8B&qid=1642078635&s=books&sprefix=1%E6%89%8B%2Cstripbooks%2C153&sr=1-4&linkCode=li3&tag=iruda02-22&linkId=896bfcbf402f12488df44fe746d304d8&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08N4D6XWP&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B08N4D6XWP"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2020年11月13日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>西田 拓也</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3rdFGh9">
                <BookTitle>1手ずつ解説する角換わり棒銀</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%EF%BC%91%E6%89%8B%E3%81%9A%E3%81%A4%E8%A7%A3%E8%AA%AC%E3%81%99%E3%82%8B%E8%A7%92%E6%8F%9B%E3%82%8F%E3%82%8A%E6%A3%92%E9%8A%80-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8BBOOKS-%E7%9C%9F%E7%94%B0%E5%9C%AD%E4%B8%80-ebook/dp/B08PZ187ZP?keywords=1%E6%89%8B%E3%81%9A%E3%81%A4%E8%A7%A3%E8%AA%AC%E3%81%99%E3%82%8B&qid=1642078635&s=books&sprefix=1%E6%89%8B%2Cstripbooks%2C153&sr=1-3&linkCode=li3&tag=iruda02-22&linkId=34f487eb945017ed3502a63e27b18b6e&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08PZ187ZP&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B08PZ187ZP"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2020年12月11日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>真田 圭一</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3FoLGc9">
                <BookTitle>1手ずつ解説する三間飛車</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/%EF%BC%91%E6%89%8B%E3%81%9A%E3%81%A4%E8%A7%A3%E8%AA%AC%E3%81%99%E3%82%8B%E4%B8%89%E9%96%93%E9%A3%9B%E8%BB%8A-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8BBOOKS-%E8%A5%BF%E5%B7%9D%E5%92%8C%E5%AE%8F-ebook/dp/B08ZYHSRFP?keywords=1%E6%89%8B%E3%81%9A%E3%81%A4%E8%A7%A3%E8%AA%AC%E3%81%99%E3%82%8B&qid=1642078635&s=books&sprefix=1%E6%89%8B%2Cstripbooks%2C153&sr=1-2&linkCode=li3&tag=iruda02-22&linkId=d2e478eda052097fecf4e303376f5877&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08ZYHSRFP&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=B08ZYHSRFP"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2021年4月13日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>西川 和宏</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3HWn8IY">
                <BookTitle>1手ずつ解説する先手中飛車</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/1%E6%89%8B%E3%81%9A%E3%81%A4%E8%A7%A3%E8%AA%AC%E3%81%99%E3%82%8B%E5%85%88%E6%89%8B%E4%B8%AD%E9%A3%9B%E8%BB%8A-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8BBOOKS-%E6%88%B8%E8%BE%BA-%E8%AA%A0/dp/4839977437?keywords=1%E6%89%8B%E3%81%9A%E3%81%A4%E8%A7%A3%E8%AA%AC%E3%81%99%E3%82%8B&qid=1642078635&s=books&sprefix=1%E6%89%8B%2Cstripbooks%2C153&sr=1-1&linkCode=li3&tag=iruda02-22&linkId=49772ecc9a3728c82813aa66816f492e&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839977437&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4839977437"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2021年8月27日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>戸部 誠</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
            <Book>
              <a href="https://amzn.to/3rm6ZGq">
                <BookTitle>1手ずつ解説する角交換四間飛車</BookTitle>
                <BookInfo>
                  <BookImg>
                    <a
                      href="https://www.amazon.co.jp/1%E6%89%8B%E3%81%9A%E3%81%A4%E8%A7%A3%E8%AA%AC%E3%81%99%E3%82%8B%E8%A7%92%E4%BA%A4%E6%8F%9B%E5%9B%9B%E9%96%93%E9%A3%9B%E8%BB%8A-%E3%83%9E%E3%82%A4%E3%83%8A%E3%83%93%E5%B0%86%E6%A3%8BBOOKS-%E9%96%80%E5%80%89-%E5%95%93%E5%A4%AA/dp/4839978905?keywords=1%E6%89%8B%E3%81%9A%E3%81%A4%E8%A7%A3%E8%AA%AC%E3%81%99%E3%82%8B&qid=1642078635&s=books&sprefix=1%E6%89%8B%2Cstripbooks%2C153&sr=1-5&linkCode=li3&tag=iruda02-22&linkId=6d2a792173db2bd03af0139345574dda&language=ja_JP&ref_=as_li_ss_il"
                      target="_blank"
                    >
                      <img
                        border="0"
                        src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839978905&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=iruda02-22&language=ja_JP"
                      />
                    </a>
                    <img
                      src="https://ir-jp.amazon-adsystem.com/e/ir?t=iruda02-22&language=ja_JP&l=li3&o=9&a=4839978905"
                      width="1"
                      height="1"
                      border="0"
                      alt=""
                      // style="border:none !important; margin:0px !important;"
                    />{" "}
                  </BookImg>
                  <BookDetailList>
                    <BookDetail>
                      <span>出版社</span>
                      <span>マイナビ出版</span>
                    </BookDetail>
                    <BookDetail>
                      <span>発売日</span>
                      <span>2022年1月17日</span>
                    </BookDetail>
                    <BookDetail>
                      <span>著者</span>
                      <span>門倉 啓太</span>
                    </BookDetail>
                    <BookDetail>
                      <span>形式</span>
                      <span>Kindle版、単行本</span>
                    </BookDetail>
                  </BookDetailList>
                </BookInfo>
              </a>
            </Book>
          </FavoriteSection>
        </Main>
      </ContentWrapper>
    </Layout>
  );
};

export default BooksPage;
